import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// components
import IconInstagram from '../icons/IconInstagram';

import css from '../css/components/Instagram.module.css';

export default () => (
  <StaticQuery
    query={intstagramQuery}
    render={data => (
      <section>
        <div className={css.instagram}>
          {data.allInstaNode.edges.map(({ node: image }) => (
            <div key={image.id} className={css.image}>
              <a
                href={`https://www.instagram.com/p/` + image.id + `/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img fluid={image.localFile.childImageSharp.fluid} />
              </a>
            </div>
          ))}
        </div>
        <div className="m-2 ta-center">
          <div className={css.link}>
            <a href={data.site.siteMetadata.instagram} target="_blank" rel="noopener noreferrer">
              <span className={css.icon}>
                <IconInstagram />
              </span>
              <span className={css.label}>Sledujte novou tvorbu</span>
            </a>
          </div>
        </div>
      </section>
    )}
  />
);

const intstagramQuery = graphql`
  query InstagramQuery {
    site {
      siteMetadata {
        instagram
      }
    }
    allInstaNode(limit: 8, sort: { fields: [timestamp], order: DESC }) {
      edges {
        node {
          id
          timestamp
          thumbnails {
            src
            config_width
            config_height
          }
          localFile {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
