import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

// components
import Container from '../components/Container';
import Quote from '../components/Quote';
import Instagram from '../components/Instagram';

// css
import css from '../css/pages/about.module.css';

const AboutPage = ({ data, location }) => {
  // const title = data.datoCmsAbout.title;
  const aboutText = data.datoCmsAbout.aboutTextNode.childMarkdownRemark.html;
  const portraitImage = data.datoCmsAbout.portrait.fluid;

  return (
    <Container title="Autorka" permalink={location.pathname}>
      <section className="wrap m-1">
        <div className={css.about}>
          <div className={css.image}>
            <Img fluid={portraitImage} />
          </div>
          <div className={`${css.body}`}>
            <div dangerouslySetInnerHTML={{ __html: aboutText }} />
          </div>
        </div>
        <div className="wrap m-4">
          <Quote />
        </div>
        <div className="wrap m-4">
          <Instagram />
        </div>
      </section>
    </Container>
  );
};

export default AboutPage;

export const query = graphql`
  query {
    datoCmsAbout {
      title
      aboutTextNode {
        childMarkdownRemark {
          html
        }
      }
      portrait {
        fluid(maxWidth: 500, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
