import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import styles from '../css/components/Quote.module.css';

class Quote extends React.Component {
  render() {
    var quoteText = this.props.data.datoCmsFrontpage.quote;
    var quoteAuthor = this.props.data.datoCmsFrontpage.quoteAuthor;

    return (
      <blockquote className={styles.quote}>
        <p className={styles.text}>{quoteText}</p>
        <cite className={styles.author}>— {quoteAuthor}</cite>
      </blockquote>
    );
  }
}

export default props => (
  <StaticQuery query={quoteQuery} render={data => <Quote data={data} {...props} />} />
);

const quoteQuery = graphql`
  query quoteQuery {
    datoCmsFrontpage {
      quote
      quoteAuthor
    }
  }
`;
